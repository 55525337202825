<template>
  <div class="search-feed">
    <div v-html="headline" class="feed-headline"></div>
    <section>
      <div class="loading" v-if="loading">
        <i class="fa fa-fw fa-spinner fa-spin fa-4x text-muted"></i>
      </div>

      <div class="loading" v-if="!loading && products.length === 0">
        <h4 class="text-muted text-center">No results available.</h4>
      </div>

      <div v-else>
        <!-- CARDS -->
        <div class="product-cards cards-feed" :class="dynamicRow" ref="feed">
          <div
            class="product-card-container"
            v-for="p in products"
            :key="p.sku"
          >
            <product-card
				:product="p.product"
				:site="site"
				:dc="dc"
				:query="query"
				:userData="userData"
				:userEmail="userEmail"
				:fusionQueryId="fusionQueryId"
				:queryPipeline="queryPipeline"
				:queryBearerToken="queryBearerToken"
				:productPosition="products.indexOf(p)"
				:signal-query-pipeline="signalQueryPipeline"
				:host="host"
              	event-category="Search Feed Card"
            />
          </div>
        </div>

        <!-- SCROLL ARROWS -->
        <div class="arrows" v-if="showArrows">
          <button @click="scrollView(-1)">
            <i class="fa fa-chevron-left"></i>
          </button>
          <button @click="scrollView(1)">
            <i class="fa fa-chevron-right"></i>
          </button>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import ProductCard from "../product-card/ProductCard.vue";
import garage from "../../mixins/garage.js";
import axios from "axios";

export default {
  name: "search-feed",
  mixins: [garage],
  components: { ProductCard },

  props: {
    heading: { type: String, default: "" },
    headingType: { type: String, default: "h3" },
    count: { type: Number, default: 4 },
    query: { type: String, default: "?q=air+filters" },
    useSelected: { type: Boolean, default: true },
    custom: { type: Array, default: () => [] },
    site: { type: String, default: "pz" },
    apiSubdomain: { type: String, default: "www" },
    timed: { type: Boolean, default: false },
    timer: { type: Number, default: 3 },
    dc: { type: String },
	signalQueryPipeline: { type: String },
	queryBearerToken: { type: String },
	userData: { type: String },
	host: { type: String }
  },

  data() {
    return {
      loading: true,
      params: "&api=1",
      products: [],
      windowWidth: 0,
    };
  },

  computed: {
    headline() {
      return (
        "<" +
        this.headingType +
        ">" +
        this.dynamicHeading +
        "</" +
        this.headingType +
        ">"
      );
    },
    dynamicHeading() {
      if (this.hasStoredRide)
        return this.heading + " for Your " + this.storedRide.name;

      return this.heading;
    },
    dynamicRow() {
      if (this.count > 3) return "row-of-4";

      return "row-of-3";
    },
    selectedQuery() {
      if (this.useSelected) {
        if (this.hasStoredRide) {
          let query;

          this.storedRide.queries.forEach((r) => {
            query += r;
          });

          return query;
        }
      }

      return "";
    },
    subdomain() {
      if (this.apiSubdomain === "") {
        if (this.site === "pz") return "https://api.partzilla.com";
        if (this.site === "bn") return "https://api.boats.net";
      }

      return this.apiSubdomain;
    },
    showArrows() {
      if (this.products.length === 0) return false;

      if (this.windowWidth > 1023 && this.products.length <= 4) return false;

      return true;
    },
  },

  methods: {
    getFeed() {
      return axios.get(
          "/search" +
          this.query +
          this.selectedQuery +
          this.params
      );
    },
    scrollView(v) {
      const feed = this.$refs.feed;

      const interval = window.innerWidth >= 1024 ? 150 : 250;

      if (feed.scrollLeft + feed.clientWidth === feed.scrollWidth) {
        feed.scroll({
          top: 0,
          left: 0,
          behavior: "smooth",
        });

        return;
      }

      feed.scroll({
        top: 0,
        left: feed.scrollLeft + v * interval,
        behavior: "smooth",
      });
    },
  },

  mounted() {
    this.windowWidth = window.innerWidth;

    window.addEventListener("resize", () => {
      this.windowWidth = window.innerWidth;
    });

    if (this.useSelected) {
      this.checkForStoredRide();

      if (this.hasStoredRide) this.getStoredRide();
    }

    if (this.query !== "") {
      this.getFeed()
        .then((results) => {
          this.loading = false;
          this.products = results.data.data.docs.products.splice(0, this.count);
          $('[data-toggle="popover"]').popover();
        })
        .catch((results) => {
          Sentry.captureException(results);
          console.log(results);
        });
    }

    if (this.timed) {
      setInterval(() => {
        this.scrollView(1);
      }, this.timer * 1000);
    }
  },
};
</script>

<style lang="scss">
div.search-feed {
  h1,
  h2,
  h3,
  h4 {
    font-weight: 700;
    text-transform: uppercase;
    color: black;
  }

  div.loading {
    padding: 11em;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .feed-headline {
    h1,
    h2,
    h3,
    h4 {
      a {
        padding-left: 0.45em;
        margin-left: 0.25em;
        border-left: 1px solid #d4d4d4;
      }
    }
  }

  .row-of-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
    @media only screen and (max-width: 768px) {
      display: flex !important;
    }
  }

  .row-of-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  div.arrows {
    width: 100%;
    display: flex;
    justify-content: space-between;

    pointer-events: none;
    padding: 0 0.5em;

    button {
      width: 64px;
      background-color: rgba(0, 0, 0, 0);
      pointer-events: all;

      outline: 0;
      border: none;

      padding: 0.5em;
      border-radius: 5px;

      &:hover {
        background-color: rgba(0, 0, 0, 0.3);
        color: white;
      }
    }
  }

  div.cards-feed {
    flex-wrap: nowrap;
    overflow-x: scroll;

    scroll-snap-type: x mandatory;

    div.product-card-container {
      min-width: 25%;
      scroll-snap-align: start;
    }
  }

  @media (max-width: 1023px) {
    div.cards-feed {
      div.product-card-container {
        min-width: 33.3%;
      }
    }
  }

  @media (max-width: 767px) {
    div.cards-feed {
      div.product-card-container {
        min-width: 50%;
      }
    }
  }

  @media (max-width: 599px) {
    div.cards-feed {
      div.product-card-container {
        min-width: 100%;
      }
    }
  }
}
</style>